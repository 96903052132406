import i18n from "i18n";
import axios from "axios";
import { odooAxios } from "axios_interceptors";
import moment from "moment";
import LegForm from "./leg_form";
import { Loader } from "react-loaders";
import React, { Fragment } from "react";
import { withRouter } from "react-router";
import Selector from "ops/common/selector";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import GetPermission from "ops/common/get_permission";
import { AlertNotification } from "ops/common/alert_notification";
import { Form, Label, Row, Col, Card, CardBody, FormGroup, Input, Button } from "reactstrap";
import { encryptData } from "ops/common/encryption_service";

class NewProject extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			project: {},
			standards: {},
			companies: [],
			instances: [],
			projectTypes: [],
			expenseTypes: [],
			selectedLegs: [],
			commodityTypes: [],
			formLoading: false,
			commoditiesForLegs: [],
			selectedCommodities: [],
			selectedCommodityType: {},
			attachedAssetSubTypes: [],
			legs: [{ id: 0, name: "" }],
			attachedAssetSizeChoices: [
				{ id: 0, name: "" },
				{ id: 1, name: "Small" },
				{ id: 2, name: "Large" },
			],
			selectedAttachedAssetSize: {},
			uoms: [{ id: 0, fullName: "" }],
			selectedAttachedAssetSubType: {},
			commodities: [{ id: 0, name: "" }],
		};
	}

	getCommodities = (e, v) => {
		if (v) {
			axios.get(`commodity/?commodityType_id__name=${v.name}`).then((res) => {
				this.setState({ commodities: res.data });
			});
			axios.get(`assetsubtype/?commodityType=${v.id}`).then((res) => {
				this.setState({ attachedAssetSubTypes: res.data });
			});
			this.setState({ selectedCommodityType: v }, () => {
				this.setDefaultProjectDescription();
			});
		} else this.setState({ commodities: [], attachedAssetSubTypes: [] });
	};

	getSelectedCommodities = (e, v) => {
		if (v) {
			let commoditiesIDs = v.map((obj) => {
				return obj.id;
			});
			let updatedPoject = { ...this.state.project };
			let targetID = e.target.id.split("-")[0];
			updatedPoject[`invalid${targetID.charAt(0).toUpperCase()}${targetID.slice(1, targetID.length)}`] = false;
			this.setState({ project: updatedPoject, selectedCommodities: commoditiesIDs, commoditiesForLegs: v });
		}
	};

	getSelectedValue = (e, v) => {
		if (v) {
			let updatedPoject = { ...this.state.project };
			let targetID = e.target.id.split("-")[0];
			updatedPoject[targetID] = v.id;
			updatedPoject[`${targetID}Object`] = v;
			updatedPoject[`invalid${targetID.charAt(0).toUpperCase()}${targetID.slice(1, targetID.length)}`] = false;
			this.setState({ project: updatedPoject }, () => {
				this.setDefaultProjectDescription();
			});
		}
	};

	getInstanceValue = (v) => {
		if (v) {
			let updatedPoject = { ...this.state.project };
			let targetID = "instance";
			updatedPoject[targetID] = v.id;
			updatedPoject[`${targetID}Object`] = v;
			updatedPoject[`invalid${targetID.charAt(0).toUpperCase()}${targetID.slice(1, targetID.length)}`] = false;
			this.setState({ project: updatedPoject }, () => {
				this.setDefaultProjectDescription();
			});
		}
		return v.name;
	};

	getValue = (e) => {
		let value = e.target.value;
		let time = moment().format("hh:mm");
		if (e.target.id === "startDate") value = `${value}T${time}`;
		if (e.target.id === "isAssessment" || e.target.id === "openEndedQTY") value = e.target.checked;
		let updatedPoject = this.state.project;
		updatedPoject[e.target.id] = value;
		updatedPoject[`invalid${e.target.id.charAt(0).toUpperCase()}${e.target.id.slice(1, e.target.id.length)}`] = false;
		this.setState({ project: updatedPoject });
	};

	addLeg = () => {
		this.setState({ selectedLegs: this.state.selectedLegs.concat({}) });
	};

	removeLeg = (e) => {
		let projectLegs = [...this.state.selectedLegs];
		projectLegs.splice(e.target.getAttribute("index"), 1);
		this.setState({ selectedLegs: projectLegs });
	};

	getLegValues = (e) => {
		let value = e.target.value;
		if (e.target.id === "isDead" || e.target.id === "isStart" || e.target.id === "isEnd") value = e.target.checked;
		let projectLegs = this.state.selectedLegs;
		projectLegs[e.target.getAttribute("index")][e.target.id] = value;
		this.setState({ selectedLegs: projectLegs });
	};

	getLegSelectedValues = (e, v, index) => {
		if (v) {
			let targetID = e.target.id.split("-")[0];
			let projectLegs = this.state.selectedLegs;
			projectLegs[index][targetID] = v.id;
			projectLegs[index][`${targetID}Object`] = v;
			this.setState({ selectedLegs: projectLegs });
		}
	};

	getBillingValues = (e) => {
		let expenses = this.state.expenseTypes;
		let index = e.target.getAttribute("index");
		let value = e.target.value,
			targetID = e.target.id;
		if (targetID === "isChecked") value = e.target.checked;
		expenses[index][targetID] = value;
		this.setState({ expenseTypes: expenses });
	};

	selectAllBillings = (e) => {
		let isChecked = e.target.checked;
		let expenses = [...this.state.expenseTypes];
		expenses.forEach((expense) => {
			expense.isChecked = isChecked;
			return expense;
		});
		this.setState({ expenseTypes: expenses });
	};

	validate = (e) => {
		let project = this.state.project;
		let targetID = e.target.id.split("-")[0];
		project[`invalid${targetID.charAt(0).toUpperCase()}${targetID.slice(1, targetID.length)}`] = true;
		this.setState({ project: project });
	};

	handleBeforeUnload = (event) => {
		event.preventDefault();
		event.returnValue = "";
	};

	// used to send any required data to odoo by passing the url and the data
	sendDataToOdoo = (odooURL, odooData) => {
		odooAxios
		  .post(odooURL, odooData, {}, {
			  headers: {
				'Content-Type': 'application/json',
			  }
		  })
		  .then((odooResponse) => {
				console.log("Data successfully sent to Odoo:", odooResponse.data.result);
		  })
		  .catch((odooError) => {
				console.error("Failed to send data to Odoo:", odooError);
		  });
	};

	submit = (e) => {
		e.preventDefault();
		let project = this.state.project;
		project["legs"] = this.state.selectedLegs;
		project["commodity"] = this.state.selectedCommodities;
		let projectExpenses = [];
		this.state.expenseTypes.forEach((e) => {
			if (e.isChecked) {
				e["expenseType_id"] = e.id;
				projectExpenses.push(e);
			}
		});
		project["expenses"] = projectExpenses;
		window.addEventListener("beforeunload", this.handleBeforeUnload);
		this.setState({ formLoading: true });
		axios
			.post(`project/`, project)
			.then(async (res) => {
				this.setState({ formLoading: false });
				// prepare required data to create new project in odoo
				const odooData = {
					projectCode: res.data.code,
					projectType: project.projectTypeObject['name'],
					projectStatus: res.data.status,
					customer: project.companyObject['fullName'],
					startDate: res.data.startDate,
				};
				if(project.instance)
					odooData.company = project.instanceObject['name'];
				const { encryptedData, authTag, iv } = await encryptData(odooData);
				const encryptedObject = {
					encrypted_data: encryptedData,
					tag: authTag,
					iv,
				}
				this.sendDataToOdoo(`new_project`, encryptedObject);
				
				if (res) this.props.history.push({ pathname: "/viewproject/", projectID: res.data.id });
			})
			.catch((err) => {
				this.setState({ formLoading: false });
				if (err.response) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
				} else AlertNotification(null, "error");
			})
			.finally(() => {
				window.removeEventListener("beforeunload", this.handleBeforeUnload);
			});
	};

	getInstances = (callback) => {
		axios.get(`profile/userinstances`).then((res) => {
			this.setState({ instances: res.data }, () => {
				if (callback) callback(); // Execute callback after instances are set
			});
		})
	}

	getInitialData = () => {
		axios.get(`company`).then((res) => {
			this.setState({ companies: res.data });
		});
		axios.get(`commoditytype`).then((res) => {
			this.setState({ commodityTypes: res.data, selectedCommodityType: {} });
		});
		axios.get(`projecttype`).then((res) => {
			this.setState({ projectTypes: res.data });
		});
		axios.get(`leg`).then((res) => {
			this.setState({ legs: this.state.legs.concat(res.data) });
		});
		axios.get(`uom/?serializer=`).then((res) => {
			this.setState({ uoms: this.state.uoms.concat(res.data) });
		});
		axios.get(`expensetype`).then((res) => {
			this.setState({ expenseTypes: res.data });
		});
	};

	getStandards = (e, v, index) => {
		let standards = { ...this.state.standards };
		axios.get(`standard/?serializer=get&isArchive=false&leg=${v.id}`).then((res) => {
			standards[index] = res.data;
			this.setState({ standards: standards });
		});
	};

	getSelectedAttachedAssetSize = (e, v) => {
		this.setState({ selectedAttachedAssetSize: v }, () => {
			this.setDefaultProjectDescription();
		});
	};

	getSelectedAttachedAssetSubType = (e, v) => {
		this.setState({ selectedAttachedAssetSubType: v }, () => {
			this.setDefaultProjectDescription();
		});
	};

	setDefaultProjectDescription = () => {
		let project = { ...this.state.project };
		project["description"] = `${this.state.project.projectTypeObject ? this.state.project.projectTypeObject.name : ""} - ${this
			.state.selectedAttachedAssetSubType.name || ""} - ${this.state.selectedAttachedAssetSize.name || ""} - ${this.state
			.selectedCommodityType.name || ""} - ${this.state.project.companyObject ? this.state.project.companyObject.fullName : ""}`;
		this.setState({ project: project });
	};

	componentDidMount() {
		this.getInitialData();
		this.getInstances(() => {
			if(this.state.instances.length === 1) {
				this.getInstanceValue(this.state.instances[0]);
			}
		});
	}

	render() {
		const t = i18n.t;
		return (
			<Fragment>
				<PageTitleAlt2 heading={t("createProject")} icon="bi bi-kanban icon-gradient bg-happy-fisher" />
				<LoadingOverlay
					tag="div"
					active={this.state.formLoading}
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					<Form onInvalid={this.validate} onSubmit={this.submit}>
						<Row>
							<Col xs="12" sm="12" md="12" lg="12" xl="12">
								<Card className="main-card mb-3" key="0">
									<CardBody>
										<Row className="d-flex justify-content-start">
											{
												this.state.instances.length > 1 &&
												<Col xs="12" sm="12" md="6" lg="2" xl="2">
													<Label>{t("company")}</Label>
													<Selector
														required
														size="small"
														id="instance"
														isObjectOption
														optionAccessor="name"
														options={this.state.instances}
														onChange={this.getSelectedValue}
														error={this.state.project["invalidInstance"]}
													/>
												</Col>
											}
											<Col xs="12" sm="12" md="6" lg="2" xl="2">
												<Label>{t("customer")}</Label>
												<Selector
													required
													size="small"
													id="company"
													isObjectOption
													optionAccessor="fullName"
													options={this.state.companies}
													onChange={this.getSelectedValue}
													error={this.state.project["invalidCompany"]}
												/>
											</Col>
											<Col xs="12" sm="12" md="6" lg="2" xl="2">
												<FormGroup>
													<Label>{t("projectType")}</Label>
													<Selector
														required
														size="small"
														isObjectOption
														id="projectType"
														optionAccessor="name"
														onChange={this.getSelectedValue}
														options={this.state.projectTypes}
														error={this.state.project["invalidProjectType"]}
													/>
												</FormGroup>
											</Col>
											<Col xs="12" sm="12" md="4" lg="2">
												<Label>{t("totalQuantity")}</Label>
												<Input
													required
													type="number"
													step={0.01}
													bsSize="sm"
													id="totalQTY"
													onChange={this.getValue}
													invalid={this.state.project["invalidTotalQTY"]}
												/>
											</Col>
											<Col xs="12" sm="12" md="4" lg="2">
												<Label>{t("targetQuantityPerDay")}</Label>
												<Input
													required
													type="number"
													step={0.01}
													bsSize="sm"
													id="targetQTYDay"
													onChange={this.getValue}
													invalid={this.state.project["invalidTargetQTYDay"]}
												/>
											</Col>
											<Col xs="12" sm="12" md="4" lg={this.state.instances.length === 1 ? "4" : "2"}>
												<Label>{t("startDate")}</Label>
												<Input
													required
													type="date"
													bsSize="sm"
													id="startDate"
													onChange={this.getValue}
													invalid={this.state.project["invalidStartDate"]}
												/>
											</Col>
											<Col xs="12" sm="12" md="6" lg="3" xl="3" className="mt-2">
												<Label>{t("commodityType")}</Label>
												<Selector
													size="small"
													isObjectOption
													id="commodityType"
													optionAccessor="name"
													onChange={this.getCommodities}
													options={this.state.commodityTypes}
													disabled={this.state.selectedCommodities.length !== 0}
												/>
											</Col>
											<Col xs="12" md="9" className="mt-2">
												<Label>{t("commodity")}</Label>
												<Selector
													multiple
													size="small"
													id="commodity"
													isObjectOption
													optionAccessor="name"
													options={this.state.commodities}
													onChange={this.getSelectedCommodities}
													error={this.state.project["invalidCommodity"]}
													required={this.state.selectedCommodities.length === 0}
												/>
											</Col>
											<Col xs="12" sm="12" md="6" lg="3" xl="3" className="mt-2">
												<Label>{t("attachedAssetType")}</Label>
												<Selector
													size="small"
													id="trailerType"
													isObjectOption
													optionAccessor="name"
													options={this.state.attachedAssetSubTypes}
													onChange={this.getSelectedAttachedAssetSubType}
												/>
											</Col>
											<Col xs="12" sm="12" md="6" lg="3" xl="3" className="mt-2">
												<Label>{t("attachedAssetSize")}</Label>
												<Selector
													size="small"
													id="trailerType"
													isObjectOption
													optionAccessor="name"
													options={this.state.attachedAssetSizeChoices}
													onChange={this.getSelectedAttachedAssetSize}
												/>
											</Col>{" "}
											<Col xs="12" sm="12" md="4" lg="3" className="mt-2">
												<Label>{t("allocatedUnits")}</Label>
												<Input
													type="number"
													bsSize="sm"
													id="allocatedUnits"
													onChange={this.getValue}
													invalid={this.state.project["invalidAllocatedUnits"]}
												/>
											</Col>
											<Col xs="12" sm="12" md="4" lg="3" className="mt-2">
												<Label>{t("rentedDays")}</Label>
												<Input
													type="number"
													bsSize="sm"
													id="rentedDays"
													onChange={this.getValue}
													invalid={this.state.project["invalidRentedDays"]}
												/>
											</Col>
											<Col xs="12" className="mt-2">
												<Label>{t("description")}</Label>
												<Input
													type="text"
													bsSize="sm"
													id="description"
													onChange={this.getValue}
													value={this.state.project.description}
												/>
											</Col>
											<Col xs="12" className="mt-2">
												<FormGroup check inline>
													<div>
														<Label check>
															<Input id="isAssessment" type="checkbox" onChange={this.getValue} />
															{" " + t("assessment")}
														</Label>
													</div>
												</FormGroup>
											</Col>
											<Col xs="12" className="mt-2">
												<FormGroup check inline>
													<div>
														<Label check>
															<Input id="openEndedQTY" type="checkbox" onChange={this.getValue} />
															{" " + t("openEndedQTY")}
														</Label>
													</div>
												</FormGroup>
											</Col>
										</Row>
										<Row className="mt-4">
											<Col xs="12">
												<Label>{t("expenses")}</Label>
											</Col>
											<Col xs="12" className="mb-3">
												<FormGroup check inline>
													<div>
														<Label check>
															<Input id="isChecked" type="checkbox" onChange={this.selectAllBillings} />
															{t("selectAll")}
														</Label>
													</div>
												</FormGroup>
											</Col>
											{this.state.expenseTypes.map((type, index) => {
												return (
													<Col xs="12" md="6" key={index}>
														<FormGroup check inline>
															<div>
																<Label check>
																	<Input
																		index={index}
																		id="isChecked"
																		type="checkbox"
																		onChange={this.getBillingValues}
																		checked={type.isChecked || false}
																	/>
																	{type.name}
																</Label>
															</div>
														</FormGroup>
														<div className="mb-2">
															<FormGroup check inline>
																<div>
																	<Input
																		bsSize="sm"
																		type="number"
																		step={0.01}
																		index={index}
																		id="refundAmount"
																		placeholder={t("refund")}
																		onChange={this.getBillingValues}
																	/>
																</div>
																<div className="ml-1">
																	<select index={index} id="isPercentage" onChange={this.getBillingValues}>
																		<option value={false}>Amount</option>
																		<option value={true}>%</option>
																	</select>
																</div>
															</FormGroup>
														</div>
													</Col>
												);
											})}
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
						<Row>
							<Col xs="12">
								<Card className="main-card mb-3">
									<CardBody>
										<div>
											<div className="dropdown-menu-header m-0">
												<div className="dropdown-menu-header-inner m-0 rounded bg-primary">
													<div className="menu-header-content">
														<h5 className="menu-header-title">Legs</h5>
													</div>
												</div>
											</div>
											<Row className="mt-3">
												{this.state.selectedLegs.map((leg, index) => {
													return (
														<Col lg="6" key={index}>
															<LegForm
																index={index}
																selectedLeg={leg}
																uoms={this.state.uoms}
																legs={this.state.legs}
																standards={this.state.standards[index]}
																onDelete={this.removeLeg}
																getValue={this.getLegValues}
																commodities={this.state.commoditiesForLegs}
																getStandards={this.getStandards}
																getSelectedValue={this.getLegSelectedValues}
															/>
														</Col>
													);
												})}
												<Col xs="12" className="d-flex justify-content-end">
													<GetPermission perm="ops.add_projectleg">
														<div code="perm">
															<Button color="dark" onClick={this.addLeg}>
																{t("addLeg")}
															</Button>
														</div>
													</GetPermission>
													<GetPermission perm="ops.add_project">
														<div code="perm">
															<Button color="info" className="ml-2" disabled={this.state.selectedLegs.length === 0}>
																{t("submit")}
															</Button>
														</div>
													</GetPermission>
												</Col>
											</Row>
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Form>
				</LoadingOverlay>
			</Fragment>
		);
	}
}

export default withRouter(NewProject);
